import ReactGA from 'react-ga4'

export const initGA = () => {
  // console.log('GA init');
  const trackingId = process.env.googleAnalytics.trackingId
  ReactGA.initialize(trackingId)
}

export const logPageView = () => {
  // console.log(`Logging pageview for ${window.location.pathname}`)
  ReactGA.set({ page: window.location.pathname })
  ReactGA.send({ hitType: "pageview", page: window.location.pathname })
}

export const logEvent = (category = '', action = '', label = '', value = 0) => {
  if (category && action) {
    const eventObject = {
      category,
      action
    }

    if (label) {
      eventObject.label = label
    }

    if (value) {
      eventObject.value = value
    }
 
    ReactGA.event(eventObject)
  }
}

export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal })
  }
}