// import '../styles/globals.css'
// import 'bootstrap/dist/css/bootstrap.css'

// function MyApp({ Component, pageProps }) {
//   return <Component {...pageProps} />
// }

// export default MyApp


import '../styles/globals.css'
import 'bootstrap/dist/css/bootstrap.css'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { initGA, logPageView } from '../utils/analytics'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    initGA();

    if (!router.asPath.includes('?')) {
      logPageView();
    }

  }, []);

  useEffect(() => {
    // Listen for page changes after a navigation or when the query changes
    router.events.on('routeChangeComplete', logPageView)
    return () => {
      router.events.off('routeChangeComplete', logPageView)
    }
  }, [router.events]);

  return(
    <Component {...pageProps}/>
  );
}

export default MyApp
